export const processPoints = [
  {
    id: '01',
    title: 'Beziehungen aufbauen',
    desc: 'Die Grundlage jedes Projekts ist die Beziehung aller Parteien. Wir beginnen mit einem Meeting in dem Ihre Ziele besprochen werden und was Sie gerne erreichen möchten, damit wir Ihre Vision verstehen'
  },
  {
    id: '02',
    title: 'Anforderungen definieren',
    desc: 'Im nächsten Schritt besprechen wir Ihr aktuelles Team, die Technologie und Management Frameworks. Danach einigen wir uns auf den Umfang und die Ziele der Kooperation und selektieren das am besten passende Kooperationsmodell.'
  },
  {
    id: '03',
    title: 'Zusammenstellung des Teams und der Technologie',
    desc: 'Mit Ihren Zielen im Blick stellen wir ein auf Ihre Bedürfnisse zugeschnittenes Team zusammen. Im Rahmen einer Projektkooperation machen wir zudem Vorschläge zur zeitlichen Abfolge und Technologie.'
  },
  {
    id: '04',
    title: 'Definition des Arbeitsablaufs',
    desc: 'Wir besprechen die Häufigkeit des Reportings, die Kommunikationswege, Arbeitsstunden und Aufgabenverwaltung, um Ihren gewünschten Arbeitsablauf zu erstellen. Dies fassen wir für Sie in einer Leistungsbeschreibung zusammen.'
  }
]
