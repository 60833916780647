import React from 'react'
import Layout from '../../components/de/Layout/Layout'
import Process from '../../components/de/Process/Process'

const ProcessPage = () => (
  <Layout
    title='Prozess | the.good.code; - Hochwertige IT-Lösungen'
    description='Lernen Sie the.good.code kennen, ein Team von Technologieexperten mit internationaler Erfahrung, die für weltweit führende Unternehmen wie Microsoft, Intel, P&G und innovative Start-ups arbeiten. Entdecken Sie unsere Mission, Vision und Werte.'
  >
    <Process />
  </Layout>
)

export default ProcessPage
