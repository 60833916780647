import React from 'react'
import ContentContainer from '../Layout/ContentContainer'
import ProcessPoint from './ProcessPoint'
import { processPointsPropTypes } from '../propTypes'
import useLineAnimation from '../hooks/useLineAnimation'

const ProcessPoints = ({ points }) => {
  const pointsRef = useLineAnimation({ start: 'top 85%' })

  return (
    <ContentContainer className='pb-24 overflow-hidden'>
      <div ref={pointsRef}>
        {points.map((point) => (
          <ProcessPoint
            key={point.id}
            {...point}
          />
        ))}
      </div>
    </ContentContainer>
  )
}

export default ProcessPoints

ProcessPoints.propTypes = {
  points: processPointsPropTypes
}

ProcessPoint.defaultProps = {
  points: []
}
