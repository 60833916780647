import clsx from 'clsx'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from 'react'
import ColorsFilter from '../../../common/ColorsFilter'

const StepWrapperClassNames = (className) =>
  clsx(
    'work-step inline-block box-content shrink-0',
    'w-[calc(100%-1.5rem)] lg:w-[calc(50%-1.5rem)]',
    className
  )

const WorkStep = ({ title, description, img, className }) => (
  <div className={StepWrapperClassNames(className)}>
    <div className='px-1'>
      {title === 'Roadmap definition' ? (
        <ColorsFilter className='rounded-2xl'>
          <GatsbyImage
            className='step-image aspect-video rounded-2xl'
            image={img?.childImageSharp?.gatsbyImageData}
            alt={title}
            loading='lazy'
            imgClassName='rounded-2xl w-14 lg:w-20'
          />
        </ColorsFilter>
      ) : (
        <GatsbyImage
          className='step-image aspect-video rounded-2xl'
          image={img?.childImageSharp?.gatsbyImageData}
          alt={title}
          loading='lazy'
          imgClassName='rounded-2xl w-14 lg:w-20'
        />
      )}
      <div className='progress-wrapper mt-6 flex items-center'>
        <div className='w-5 h-5 mr-2 bg-brand-primary rounded-full shrink-0' />
        <div className='progress-bar w-full h-0.5 bg-brand-primary/20'>
          <span className='block w-full h-full bg-brand-primary' />
        </div>
      </div>
      <div className='mt-2 lg:mt-4 xl:mt-6 whitespace-pre-wrap'>
        <h3 className='mb-2 text-2xl leading-7 lg:text-3xl lg:leading-snug'>
          {title}
        </h3>
        <p className='text-lg leading-6 font-normal lg:text-xl lg:leading-7'>
          {description}
        </p>
      </div>
    </div>
  </div>
)

export default WorkStep

WorkStep.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired
    })
  }),
  className: PropTypes.string
}

WorkStep.defaultProps = {
  title: '',
  description: '',
  img: null,
  className: ''
}
