import { useEffect, useRef } from 'react'
import useMatchesBreakpoint from './useMatchesBreakpoint'
import { gsap } from '../../gsap'

const opacityAnimationDuration = 0.1

const useWorkCycleAnimation = (withFooter = false) => {
  const animationContainerRef = useRef(null)
  const triggerRef = useRef(null)
  const matches = useMatchesBreakpoint()

  let stepsToShow = 1

  if (matches('xl')) {
    stepsToShow = 2
  } else if (matches('lg')) {
    stepsToShow = 2
  }

  useEffect(() => {
    const animationContainer = animationContainerRef.current
    const trigger = triggerRef.current
    const { offsetLeft: triggerOffsetLeft } = trigger

    const footer = withFooter
      ? document.body.querySelector('#main-footer')
      : null

    const ctx = gsap.context(() => {
      const progressBarsArr = gsap.utils.toArray('.progress-bar')
      const workStepsArr = gsap.utils.toArray('.work-step')
      const stepImagesArr = gsap.utils.toArray('.step-image')

      const workStepWidth = workStepsArr[0].offsetWidth

      withFooter &&
        gsap.set(footer, {
          maxHeight: 0,
          padding: 0,
          overflow: 'hidden'
        })

      const mainAnimation = gsap.to(workStepsArr, {
        xPercent: -100 * (workStepsArr.length - stepsToShow),
        ease: 'none',
        scrollTrigger: {
          trigger,
          pin: animationContainer,
          scrub: 0.5,
          start: 'center center',
          end: () => '+=' + 5000
        }
      })

      const stepsAnimationTrigger = (step, idx) => ({
        trigger: step,
        containerAnimation: mainAnimation,
        // -1px to make sure the trigger catches the target
        start: () =>
          'left left+=' +
          (triggerOffsetLeft +
            idx * ((workStepWidth * stepsToShow) / workStepsArr.length) -
            1),
        end: () =>
          '+=' +
          ((workStepWidth * (workStepsArr.length - stepsToShow)) /
            workStepsArr.length -
            1)
      })

      workStepsArr.forEach((step, idx) => {
        gsap.from(progressBarsArr[idx].children, {
          width: 0,
          ease: 'none',
          scrollTrigger: {
            ...stepsAnimationTrigger(step, idx),
            scrub: 0.1
          }
        })

        let isOverlayCreated = false

        gsap.to(stepImagesArr[idx], {
          duration: opacityAnimationDuration,
          scrollTrigger: {
            ...stepsAnimationTrigger(step, idx),
            toggleActions: 'restart restart restart reverse'
          },
          onComplete: () => {
            if (!isOverlayCreated) {
              const filter = document.createElement('div')
              filter.classList.add(
                'absolute',
                'top-0',
                'left-0',
                'w-full',
                'h-full',
                'rounded-2xl'
              )
              if (idx > 0) {
                filter.classList.add('mix-blend-multiply', 'bg-brand-primary')
              }
              stepImagesArr[idx].appendChild(filter)
              isOverlayCreated = true
            }
          },
          onReverseComplete: () => {
            if (isOverlayCreated) {
              const overlay = stepImagesArr[idx].querySelector(
                '.mix-blend-multiply'
              )
              overlay?.remove()
              isOverlayCreated = false
            }
          }
        })
      })
    }, triggerRef)

    return () => {
      ctx.revert()
    }
  }, [stepsToShow])

  return {
    animationContainerRef,
    triggerRef
  }
}

export default useWorkCycleAnimation
