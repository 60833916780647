import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import WorkStep from './WorkStep'
import { graphql, useStaticQuery } from 'gatsby'

export const query = graphql`
  {
    allFile(
      sort: { fields: [childMarkdownRemark___frontmatter___id], order: ASC }
      filter: {
        sourceInstanceName: { eq: "content" }
        relativeDirectory: { eq: "workCycle" }
        name: { regex: "/[.]de/" }
      }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              img {
                childImageSharp {
                  gatsbyImageData
                }
              }
              title
              description
            }
          }
        }
      }
    }
  }
`

const allStepsWrapper = (className) =>
  clsx(
    'w-[calc(100vw-1.5rem)] lg:w-[calc(100vw-3rem)] xl:w-full',
    'flex',
    'mb-8 lg:mb-0 overflow-hidden whitespace-nowrap',
    className
  )

const WorkSteps = ({ className }) => {
  const data = useStaticQuery(query)

  return (
    <div className={allStepsWrapper(className)}>
      {data.allFile.edges.map((step) => {
        const { frontmatter } = step.node.childMarkdownRemark
        return (
          <WorkStep
            key={frontmatter.title}
            {...frontmatter}
          />
        )
      })}
    </div>
  )
}

export default WorkSteps

WorkSteps.propTypes = {
  className: PropTypes.string
}

WorkSteps.defaultProps = {
  className: ''
}
