import React from 'react'
import { processPointPropTypes } from '../propTypes'
import FadeInUpAnimation from '../FadeInUpAnimation/FadeInUpAnimation'

const ProcessPoint = ({ id, title, desc }) => (
  <div className='mb-10 last:mb-0 lg:mb-20 lg:grid lg:grid-cols-12 font-light overflow-hidden'>
    <div className='line h-[1px] w-full bg-line-grey lg:col-span-12' />
    <FadeInUpAnimation
      className='block lg:col-span-5 h-fit text-brand-primary text-[200px] leading-tight'
      as='span'
    >
      {id}.
    </FadeInUpAnimation>
    <div className='h-fit my-5 lg:mt-10 lg:col-span-7'>
      <FadeInUpAnimation
        className='mb-6 text-2xl leading-8 font-light lg:text-5xl lg:leading-snug'
        as='h2'
      >
        {title}
      </FadeInUpAnimation>
      <FadeInUpAnimation
        className='xl:mt-8 text-md leading-7 lg:text-2xl lg:leading-9 xl:text-3xl xl:leading-snug'
        as='p'
      >
        {desc}
      </FadeInUpAnimation>
    </div>
  </div>
)

export default ProcessPoint

ProcessPoint.propTypes = processPointPropTypes

ProcessPoint.defaultProps = {
  id: '',
  title: '',
  desc: ''
}
