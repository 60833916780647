import { useEffect, useState } from 'react'
import tailwindConfig from '../../../../tailwind.config'
import resolveConfig from 'tailwindcss/resolveConfig'
import { throttle } from 'lodash'

const fullConfigTailwind = resolveConfig(tailwindConfig)
const breakpoints = fullConfigTailwind.theme.screens

const getPixelsSize = (breakpointName) =>
  parseInt(breakpoints[breakpointName].slice(0, -2))

const useMatchesBreakpoint = () => {
  const [screenSize, setScreenSize] = useState(undefined)

  useEffect(() => {
    setScreenSize(window.innerWidth)

    const handleResizeThrottled = throttle(
      () => setScreenSize(window.innerWidth),
      100
    )

    window.addEventListener('resize', handleResizeThrottled)

    return () => {
      window.removeEventListener('resize', handleResizeThrottled)
    }
  }, [])

  return (sizeName) => screenSize >= getPixelsSize(sizeName)
}

export default useMatchesBreakpoint
